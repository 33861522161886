<template>
  <div>
    <div class="card">
      <div class="card-body">
        <form action="">
          <div class="form-row">
            <div class="col-md-4">
              <a-range-picker @change="onDateRangeChange" />
            </div>
            <div class="col-md-4">
              <a-button class="btn btn-success btn-block" :loading="btnLoading" @click.prevent="search()"><i class="fa fa-search mr-1" aria-hidden="true"></i>Search</a-button>
            </div>
          </div>
        </form>
      </div>
    </div>
    <div class="card">
      <div class="card-body">
        <div class="table-responsive">
          <table class="table table-bordered table-sm text-center" id="purchase_order_list">
            <thead>
            <tr>
              <th>#</th>
              <th>Transaction No.</th>
              <th>Status</th>
              <th>Requested Amount(BDT)</th>
              <th>Expense Month</th>
              <th>Action</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(transaction, index) in transactions.data" :key="index">
              <td>{{ transactions.from + index}}</td>
              <td>{{ transaction.txid }}</td>
              <td>
                <a href="javascript: void(0);" v-b-modal.request-transaction-status-modal @click="$bvModal.show('request-transaction-status-modal'), status(transaction.max_requested_transaction_status, transaction)" class="btn btn-sm btn-primary">
                  <i class="fe fe-eye"/>
                </a>
              </td>
              <td>{{ currencyConverter(transaction.total_requested_amount)}}</td>
              <td>{{ customRequestedAtDate(transaction.requested_at) }}</td>
              <td>
                <router-link :to="{ name: 'hubExpenseView', params: { requested_transaction_id: transaction.id } }" class="btn btn-sm btn-info mr-2" tag="a">
                  <i class="fe fe-eye"/> View
                </router-link>
              </td>
            </tr>
            </tbody>
          </table>
          <a-skeleton active :loading="loading"></a-skeleton>
          <h5 class="text-center text-secondary" v-if="transactions.total === 0 || !flag">
            <Empty :image="simpleImage"></Empty>
          </h5>
          <hr>
          <div class="float-right">
            <pagination class="mt-2" :data="transactions" :limit="2" @pagination-change-page="getResults"></pagination>
          </div>
        </div>
      </div>
    </div>
    <status :current="current" :transaction="transaction"></status>
  </div>
</template>

<script>
import { Empty } from 'ant-design-vue'
import moment from 'moment'
import apiClient from '@/services/axios'
import Status from '@/views/expense/partial/status'

export default {
  name: 'List',
  components: { Empty, Status },
  data() {
    return {
      transactions: {},
      transaction: {},
      loading: false,
      loader: false,
      flag: false,
      btnLoading: false,
      pdfDownloadLoader: false,
      excelDownloadLoader: false,
      simpleImage: Empty.PRESENTED_IMAGE_SIMPLE,
      current: 0,
      search_data: {
        date_range: '',
      },
    }
  },
  mounted() { },
  methods: {
    onDateRangeChange(date, dateString) {
      this.search_data.date_range = dateString
    },
    customDate(date) {
      return moment(date).format('LL')
    },
    customRequestedAtDate(date) {
      return moment(date).format('MMMM YYYY')
    },
    search() {
      this.loading = true
      this.btnLoading = true
      this.flag = true
      apiClient.post('api/hub/previous/transaction/search', this.search_data)
        .then(response => {
          this.loading = false
          this.btnLoading = false
          this.flag = true
          this.transactions = response.data.transactions
        })
        .catch(error => {
          console.log(error)
        })
    },
    getResults(page = 1) {
      apiClient.post('api/hub/previous/transaction/search?page=' + page, this.search_data)
        .then(response => {
          this.transactions = response.data.transactions
        })
    },
    status(itemTransactionStatus, transaction) {
      this.current = 0
      this.transaction = ''
      this.current = itemTransactionStatus
      this.transaction = transaction
    },
    pdfDownload(requestTransactionId, index) {
      this.pdfDownloadLoader = true
      apiClient.get('api/transaction/pdf-download/' + requestTransactionId, { responseType: 'blob' }).then(response => {
        this.pdfDownloadLoader = false
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'transaction.pdf')
        document.body.appendChild(link)
        link.click()
      }).catch(error => {
        console.log(error)
      })
    },
    excelDownload(requestTransactionId, index) {
      this.excelDownloadLoader = true
      apiClient.get('api/transaction/excel-download/' + requestTransactionId, { responseType: 'blob' }).then(response => {
        this.excelDownloadLoader = false
        const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/vnd.ms-excel' }))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'transaction.xlsx')
        document.body.appendChild(link)
        link.click()
      }).catch(error => {
        console.log(error)
      })
    },
    currencyConverter(x) {
      return this.$localCurrency(x)
    },
  },
}
</script>

<style scoped>
.form-control-sm {
  height: calc(1.7em + .5rem + 2px) !important;
}
</style>
